@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap');

html {
  outline: none;
}

body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url(background.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


#pop-box-placholder {
  border: none;
  border-radius: .3rem;
  width: 100%;
  height: 100%;
}

#studio {
  height: 100%;
  width: 100%;
  position: fixed;
}

/*----------------------------BOOTSTRAP OVERRIDES--------------------------------*/

.modal-title {
  font-weight: 900;
}

.modal-content {
  border:none;
  height: 100%;
  background: rgba(255, 255, 255, 0.35);
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
}

.modal-header {
  border-bottom: none;
  color: #fff;
  padding: .1rem 1rem;
  background: #512da8a6;
  height: auto;
}

.modal-header .close {
  outline: none;
}

.close {
  text-shadow: none;
  opacity: 1;
  margin-right: -27px !important;
  color: #fff;
  font-weight: 400;
}

.close:hover {
  color: #fff;
}

.modal-dialog {
  max-width: 80%;
  height: 80%;
  width: 100%;
  border-radius: .5rem;
  background: transparent;
}

.modal-body {
  height: 100%;
  width: 100%;
  background: #ffffff;
  padding: .3rem;
  border-radius: 0 0 .3rem .3rem;
}

button:focus {
  outline: none;
}


::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #F5F5F5;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  background-color: #525252;
}

@media (max-width: 576px) {
  .modal-dialog {
    margin: .5rem auto;
  }
}