@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap);
html {
  outline: none;
}

body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url(/static/media/background.88acc637.jpg) no-repeat center center fixed;
  background-size: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


#pop-box-placholder {
  border: none;
  border-radius: .3rem;
  width: 100%;
  height: 100%;
}

#studio {
  height: 100%;
  width: 100%;
  position: fixed;
}

/*----------------------------BOOTSTRAP OVERRIDES--------------------------------*/

.modal-title {
  font-weight: 900;
}

.modal-content {
  border:none;
  height: 100%;
  background: rgba(255, 255, 255, 0.35);
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
}

.modal-header {
  border-bottom: none;
  color: #fff;
  padding: .1rem 1rem;
  background: #512da8a6;
  height: auto;
}

.modal-header .close {
  outline: none;
}

.close {
  text-shadow: none;
  opacity: 1;
  margin-right: -27px !important;
  color: #fff;
  font-weight: 400;
}

.close:hover {
  color: #fff;
}

.modal-dialog {
  max-width: 80%;
  height: 80%;
  width: 100%;
  border-radius: .5rem;
  background: transparent;
}

.modal-body {
  height: 100%;
  width: 100%;
  background: #ffffff;
  padding: .3rem;
  border-radius: 0 0 .3rem .3rem;
}

button:focus {
  outline: none;
}


::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #F5F5F5;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  background-color: #525252;
}

@media (max-width: 576px) {
  .modal-dialog {
    margin: .5rem auto;
  }
}
.App {
  height: 100vh;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@charset "utf-8";
/* CSS Document */
/*.background {*/
/*  background: url(assets/background.jpg) no-repeat center center fixed;*/
/*  -webkit-background-size: cover;*/
/*  -moz-background-size: cover;*/
/*  -o-background-size: cover;*/
/*  background-size: cover;*/
/*}*/

.stripe {
  background: #6806c2;
}

.top_head {
  width: 100%;
  text-align: center;
  padding: 30px 30px 30px 30px;
}
.form-wrapper {
  width: 70%;
  position: relative;
  margin: 50px auto;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
}
.form {
  padding: 50px 30px 50px 30px;
}
.form-wrapper h1 {
  background: #6806c2;
  color: #fff;
  font-size: 22px;
  text-align: left;
  padding: 30px 30px 30px 30px;
  margin: 0;
}
.form-group {
  position: relative;
}
.form-group + .form-group {
  margin-top: 20px;
}
.form-label {
  position: absolute;
  left: 0;
  top: 10px;
  color: #000;
  z-index: 10;
  padding-left: 10px;
  transition: transform 150ms ease-out, font-size 150ms ease-out;
}
.focused .form-label {
  transform: translateY(-125%);
  font-size: 0.75em;
  left: -10px;
}
.form-input {
  position: relative;
  padding: 5px 0 5px 0;
  width: 100%;
  top: 5px;
  border-radius: 2px;
  outline: 0;
  padding-left: 10px;
  border: 1px solid #fff;
  border-bottom: 2px solid #6806c2;
}
.form-input:focus {
  border-bottom: 2px solid #6806c2;
  top: 5px;
}
.form-input.filled {
  border-bottom: 2px solid #6806c2;
  top: 5px;
}
.form-select {
  position: absolute;
  left: 0;
  top: 10px;
  color: #000;
  z-index: 10;
  padding: 4px 0;
  transition: transform 150ms ease-out, font-size 150ms ease-out;
}
.focused .form-select {
  transform: translateY(-125%);
  font-size: 0.75em;
  left: -10px;
}
.form-select {
  position: relative;
  width: 100%;
  top: 5px;
  border-radius: 2px;
  outline: 0;
  padding: 5px 0 5px 5px;
  border: 1px solid #fff;
  border-bottom: 2px solid #6806c2;
}
.form-select:focus {
  border-bottom: 2px solid #6806c2;
  top: 5px;
}
.form-select.filled {
  border-bottom: 2px solid #6806c2;
  top: 5px;
}
.checkbox {
  margin-left: 20px;
}
.form p {
  margin: 0;
  font-weight: 600;
}
.subbtn {
  background: #6806c2;
  color: #fff;
}
.subbtn:hover {
  background: #6806c2;
  color: #fff;
}
.border-bot {
  position: absolute;
  bottom: -9px;
}

.validationError {
  text-align: left;
  margin-left: 0.6em;
  color: red;
}

.buttonsContainer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.buttonsContainer a {
  background-color: #6806c2;
  color: white;
  padding: 0.7em 4em;
  border-radius: 0.6em;
  text-decoration: none;
}

.registerButtonContainer,
.loginButtonContainer {
  display: flex;
  flex-direction: column;
}


@media only screen and (max-width: 900px) {
  .form-wrapper h1 {
    font-size: 18px;
  }
  .form-wrapper {
    width: 100%;
    font-size: 13px;
    padding-bottom: 30px;
  }
  .form {
    padding: 30px 15px 30px 15px;
  }
  .form-label {
    font-size: 12px;
  }

  .buttonsContainer a {
    padding: 0.7rem 3rem;
  }
  .MuiFormLabel-root {
    font-size: .7rem;
  }
}


